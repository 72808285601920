import React from 'react'

interface SubmitButtonProps {
  children?: string
  className?: string
  isDisabled?: boolean
  compact?: 'all' | 'sm' | 'md' | 'lg' | 'xl'
  type?: 'button' | 'submit' | 'reset'
  onClick?: () => void
}

const SubmitButton = ({
  children,
  className,
  isDisabled,
  compact,
  type = 'submit',
  onClick,
}: SubmitButtonProps): JSX.Element => {
  const buttonStyle = compact
    ? compact === 'all'
      ? 'w-auto'
      : `w-full ${compact}:w-auto`
    : 'w-full'

  return (
    <button
      type={type}
      className={`${buttonStyle} select-none px-6 font-bold p-2 rounded text-white text-center transition duration-150 ease-in-out bg-blue-700 hover:bg-blue-600 active:bg-blue-800 focus:outline-none focus:shadow-outline disabled:bg-blue-300 disabled:cursor-not-allowed ${className}`}
      disabled={isDisabled}
      onClick={onClick}
    >
      {children}
    </button>
  )
}

export default SubmitButton
